a {
    display: inline-block;
    position: relative;
    padding: 20px 50px;
    text-decoration: none;
    text-transform: uppercase;
    width: 200px;
    overflow: hidden;
}

a span {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 20px;
    letter-spacing: 8px;
}

.liquid {
    position: absolute;
    top: -80px;
    left: 0;
    width: 200px;
    height: 200px;
    background-color: #4973ff;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}

 .liquid::before,
 .liquid::after {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: 0;
    left: 0;
    transition: 0.5s;
}

:hover .liquid {
    top: 0;
}

:hover .liquid::before {
    left: 50%;
}

:hover .liquid::after {
    left: 100%;
}

@keyframes float {
    0%, 100% {
        transform: translatey(0);
    }
    50% {
        transform: translatey(-10px);
    }
}
