body {
  margin: 0;
  padding: 0; /* Ensure no padding */
  width: 100%; /* Set width to full */
  height: 100%; /* Set height to full */
  overflow-x: hidden; /* Prevent horizontal scroll */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Jameel Noori";
  src: url("./fonts/JameelNoori.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
